:root {
  --font-family: 'Jost', sans-serif;
  --color-primary: #ee008d;
  --color-text: #ffffff;
  --color-background: #000000;
  --font-size: 16px;
  --space: 20px;
  --column: 300px;
  --gap: calc(var(--space) * 2);
  --max-width: 1200px;
  --easing: cubic-bezier(0.4, 0, 0.2, 1);
  --transition: all 0.3s var(--easing);
}

body {
  font-family: var(--font-family);
  background-color: var(--color-background);
  color: var(--color-text);
  height: 100vh;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}

html {
  height: 100%;
}

.loading {
  position: absolute;
  top: 345px;
  text-align: center;
}
