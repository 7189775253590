.active {
  padding: 0px 8px 0px 8px;
  list-style: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  border-bottom: 3px solid var(--color-primary);
}

.normal {
  padding: 0px 8px 0px 8px;
  list-style: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
}
