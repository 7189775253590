.myModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 96px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: black;
  z-index: 60;
  overflow-y: scroll;
}

.modalStyling {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.modalStylingText {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 96px;
  display: flex;
}

.closeBtnStyling {
  max-width: 1200px;
  position: absolute;
  top: 3%;
  right: 5%;
}

.modalParagraph {
  width: 75%;
}

.modalParagraph p {
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 175%;
  padding-right: 40px;
  margin-bottom: 0px;
  white-space: pre-wrap;
}

.modalParagraph div {
  margin-bottom: 32px;
}

.modalTitle {
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.modalCredits {
  margin-top: 72px;
}

.modalCredits p {
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 175%;
  text-align: left;
  margin-bottom: 0px;
}

.links {
  padding-top: 24px;
}

.clicked {
  cursor: pointer;
}

@media (max-width: 768px) {
  .modalStyling {
    display: block;
  }

  .modalStylingText {
    display: block;
  }

  .modalParagraph {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .modalParagraph p {
    font-size: 16px;
  }
}
