.verticalCenter {
  transform: translateY(100px);
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  font-size: 64px;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 10px;
  text-shadow: 5px -3px 0px var(--color-primary);
}

.year {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 36px;
  padding: 12px 24px 12px 24px;
  background-color: var(--color-primary);
  box-shadow: 9px 7px #ffff05;
  transform: skew(-20deg);
  margin-bottom: 24px;
}

.clicked {
  cursor: pointer;
}

.containerStyling {
  margin-bottom: 48px;
}

.copyrightText {
  font-size: 8px;
  text-align: right;
}
