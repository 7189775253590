.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.wrapper {
  border-bottom: 1px solid black;
  overflow: hidden;
}

.nameTitle {
  margin-top: 20px;
  font-size: 28px;
  line-height: 30px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 8px;
}

.position {
  font-family: 'Jost', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
}

.animeTitle {
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0px;
}

.animeTitleRole {
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.wrapper .questionContainer {
  width: 100%;
  text-align: left;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
}

.wrapper .arrow {
  font-size: 2rem;
  transition: 0.5s ease-in-out;
}

.arrow.active {
  rotate: 180deg;
}

.wrapper .answerContainer {
  padding: 0 1rem;
  transition: height 0.7s ease-in-out;
}

.wrapper .answerContent {
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .gridContainer {
    display: block;
  }
}
