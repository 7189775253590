.title {
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  font-size: 64px;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 10px;
  text-shadow: 5px -3px 0px var(--color-primary);
}

.tabContainer {
  width: 80%;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionContainer1 {
  margin-top: 96px;
  padding-left: 24px;
  padding-right: 24px;
}

.sectionContainer2 {
  margin-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
}

.imgStyle {
  width: 100%;
  aspect-ratio: 16/9;
  padding: 8px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.largeImg {
  width: 100%;
}
