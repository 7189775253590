.marginStyling {
  margin-top: 40px;
  margin-bottom: 0px;
}

.sectionContainer1 {
  margin-top: 96px;
  padding-left: 24px;
  padding-right: 24px;
}

.sectionContainer2 {
  margin-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
}

.sectionContainer2 p {
  font-size: 20px;
  line-height: 175%;
  font-weight: 300;
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  font-size: 64px;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 10px;
  text-shadow: 5px -3px 0px var(--color-primary);
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paragraphStyling {
  margin-right: 24px;
}

.secondaryTitleAwards {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 36px;
  padding: 12px 24px 12px 24px;
  background-color: var(--color-primary);
  box-shadow: 9px 7px #ffff05;
  transform: skew(-20deg);
  margin-bottom: 24px;
}

.secondaryTitleStaff {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 36px;
  padding: 12px 24px 12px 24px;
  background-color: #2ab2ff;
  box-shadow: 9px 7px var(--color-primary);
  transform: skew(-20deg);
  margin-bottom: 24px;
}

.prizesflexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.awardItem img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.awardItem p {
  font-size: 20px;
  line-height: 175%;
  text-align: center;
  margin-bottom: 0px;
}

.staffName {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  font-style: italic;
}

.staffTitle {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 20px;
}

.responsiveImage {
  width: 100%;
  height: auto;
  max-width: 1120px;
}

.responsiveImageSmall {
  width: 100%;
  height: auto;
  max-width: 600px;
}

.flexContainer img {
  max-width: 600px;
}

/* For smaller screen sizes (mobile) */
@media (max-width: 576px) {
  .title {
    font-size: 32px;
  }
  .secondaryTitleAwards,
  .secondaryTitleStaff {
    font-size: 24px;
  }
  .sectionContainer2 p {
    font-size: 16px;
  }
  .awardItem p {
    font-size: 16px;
  }
  .staffName {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .flexContainer {
    display: block;
  }
}
