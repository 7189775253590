.container {
  padding: 20px;
}

.navLink {
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 175%;
}

.activeNavLink {
  color: var(--color-primary) !important;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 175%;
}

.activeNavLink:hover {
  color: var(--color-primary) !important;
}

.navLink:hover {
  color: white;
}

.mobileNav {
  width: 100%;
  background-color: black;
  display: block;
  flex-direction: column;
}
