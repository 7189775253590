.topNav {
  max-width: 1200px;
  z-index: 10;
  height: 64px;
  display: flex;
  justify-content: space-between;
  background-color: rgb(0, 0, 0);
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.rightNav {
  display: flex;
  align-items: center;
}

.mobileNavButton {
  display: none;
}

.desktopNav {
  display: flex;
  align-items: center;
}

.active {
  display: flex;
  align-items: center;
}

.mobileNavContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  display: none;
}

.mobileNavVisible {
  display: block;
  animation: slideDown 0.3s ease-out forwards;
}

.mobileNavHidden {
  display: block;
  animation: slideUp 0.3s ease-out forwards;
}

.hamburgerContainer {
  margin-left: 24px;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

/* Toggle visibility based on screen size */
@media (max-width: 768px) {
  .mobileNavButton {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .desktopNav {
    display: none;
  }
}
