.sectionContainer1 {
  margin-top: 128px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 96px;
}

.secondaryTitleContact {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 36px;
  padding: 12px 24px 12px 24px;
  background-color: var(--color-primary);
  box-shadow: 9px 7px #2ab2ff;;
  transform: skew(-20deg);
  margin-bottom: 24px;
}

.info {
  font-size: 20px;
  font-weight: 300;
  line-height: 175%;
  white-space: nowrap;
}


/* For smaller screen sizes (mobile) */
@media (max-width: 576px) {
  .secondaryTitleContact {
    font-size: 24px;
  }
}