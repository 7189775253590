.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconContainer {
  display:flex;
  width: 200px;
  justify-content: space-around;
  margin-bottom: 48px;
}

.paragraphText {
  padding: 24px 0px 24px 0px;
  font-size: 20px;
  line-height: 175%;
  font-weight: 300;
}
