.sectionContainer {
  padding-left: 24px;
  padding-right: 24px;
}

.sectionContainerWorks {
  margin-top: 96px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 10px;
  font-family: 'Noto Sans JP', sans-serif;
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  font-size: 64px;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 10px;
  text-shadow: 5px -3px 0px var(--color-primary);
}

.secondaryTitleWorks {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 36px;
  padding: 12px 24px 12px 24px;
  background-color: var(--color-primary);
  box-shadow: 9px 7px #ffff05;
  transform: skew(-20deg);
  margin-bottom: 24px;
}

.secondaryTitleCollab {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 36px;
  padding: 12px 24px 12px 24px;
  background-color: #2ab2ff;
  box-shadow: 9px 7px var(--color-primary);
  transform: skew(-20deg);
  margin-bottom: 24px;
}

.collaboratorRow {
  margin-bottom: 20px;
}

.viewAllBtn {
  display: block;
  margin: 0 auto;
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #ffff05;
  background-color: transparent;
  border-color: #ffff05;
  padding: 16px 40px 16px 40px;
  letter-spacing: 4px;
  border-radius: 0px;
  margin-bottom: 48px;
}

.viewAllBtn:hover {
  background-color: transparent;
  border-color: #ffff05;
  color: #ffff05;
}

.paragraphTextStyling {
  padding-top: 24px;
  font-family: 'Jost', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 175%;
}

.customParagraphStyling {
  margin-bottom: 0px;
}

.customParagraphStyling2 {
  margin-bottom: 24px;
}

.shakaPlayerContainer {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  margin-bottom: -220px;
  z-index: -10;
}

.responsiveVideo {
  width: 100%;
  height: auto;
  max-width: 100vw;
}

/* For smaller screen sizes (mobile) */
@media (max-width: 576px) {
  .mobileContainer {
    margin-top: 150px;
  }
  .title {
    font-size: 32px;
  }
  .secondaryTitleWorks,
  .secondaryTitleCollab {
    font-size: 24px;
  }
  .paragraphTextStyling {
    font-size: 16px;
  }
}
