.btnBackground {
  background-color: black;
  border-color: white;
  border-radius: 0px;
}

.btnBackground:hover {
  background-color: white;
  color: black;
  border-radius: 0px;
}

.btnBackgroundActive {
  background-color: black;
  color: black;
  border-radius: 0px;
}

#navDropdown {
  color: white;
  padding: 0px;
}

.navContainer {
  border-radius: 2px;
  border-color: white;
}